











































import NavbarMenu from '@/components/NavbarMenu.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {Component} from 'vue-property-decorator'
import AppFooter from '@/components/AppFooter.vue'
import TokensPriceTable from '@/components/tokensprice/TokensPriceTable.vue'
import {MetaInfo} from 'vue-meta'
import GetGasOpenModalButton from '@/components/getGas/GetGasOpenModalButton.vue'
import LastPriceUpdated from '@/components/LastPriceUpdated.vue'
import PoweredBy, {PoweredByItem} from '@/components/PoweredBy.vue'
import FilterNetwork from '@/components/filters/FilterNetwork.vue'

@Component({
  components: {
    PoweredBy,
    LastPriceUpdated,
    AppFooter,
    NavbarMenu,
    TokensPriceTable,
    GetGasOpenModalButton,
    FilterNetwork,
  },
})
export default class TokensPriceView extends MixinScreenSize {
  lastTimeUpdated = ''

  poweredByList = [
    {
      url: 'https://flamingo.finance/',
      alt: 'flamingo icon',
      filename: 'flamingo-icon.svg',
    } as PoweredByItem,
    {
      url: 'https://www.forthewin.network/#/',
      alt: 'ftwicon',
      filename: 'for-the-win-logo.svg',
    } as PoweredByItem,
    {
      url: 'https://neofura-1.gitbook.io/api-docs/',
      alt: 'magnet icon',
      filename: 'magnet-icon.svg',
    } as PoweredByItem,
  ]

  metaInfo(): MetaInfo {
    return {
      title: this.$translate('metadata.title.tokens'),
    }
  }

  handleChangeLastTimeUpdated(lastTimeUpdated: string) {
    this.lastTimeUpdated = lastTimeUpdated
  }

  handleFilterNetwork() {
    // TODO: Implement filter network
  }
}
